import React from 'react'

export const constant_multiple = {
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.42828 17.1297C9.44086 17.8362 10.6717 18.25 12 18.25C13.7566 18.25 15.3429 17.5263 16.4792 16.3588C17.5758 15.2321 18.25 13.6956 18.25 12H19.75C19.75 14.1019 18.9123 16.0096 17.5541 17.405C16.1471 18.8507 14.1779 19.75 12 19.75C10.1056 19.75 8.36905 19.0695 7.02307 17.941L6.12603 18.4589C5.78112 18.658 5.35318 18.3936 5.37692 17.996L5.61031 14.0883C5.63171 13.7301 6.01227 13.5104 6.33316 13.671L9.83405 15.4227C10.1902 15.6009 10.2052 16.1037 9.86031 16.3029L8.42828 17.1297ZM12 5.75C8.54822 5.75 5.75 8.54822 5.75 12H4.25C4.25 7.71979 7.71979 4.25 12 4.25C14.0406 4.25 15.8979 5.03951 17.2813 6.32812L18.1596 5.82106C18.5045 5.62192 18.9325 5.88632 18.9087 6.28388L18.7004 9.77095C18.679 10.1291 18.2985 10.3489 17.9776 10.1883L14.8536 8.62512C14.4974 8.4469 14.4824 7.9441 14.8273 7.74496L15.9072 7.12149C14.8367 6.26282 13.4787 5.75 12 5.75Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
}
